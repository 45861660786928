import Certificate1 from "../img/Certificate1.png";
import Certificate2 from "../img/Certificate2.jpg";
import Certificate3 from "../img/Certificate3.png";
// import Certificate4 from "../img/Certificate4.png";
// import Certificate5 from "../img/Certificate5.png";
// import Certificate6 from "../img/Certificate6.png";
// import Certificate7 from "../img/Certificate7.png";
// import Certificate8 from "../img/Certificate8.png";
// import Certificate9 from "../img/Certificate9.png";
// import Certificate10 from "../img/Certificate10.png";
// import Certificate11 from "../img/Certificate11.png";
// import Certificate12 from "../img/Certificate12.png";
// import Certificate13 from "../img/Certificate13.png";
// import Certificate14 from "../img/Certificate14.png";
// import Certificate15 from "../img/Certificate15.png";
// import Certificate16 from "../img/Certificate16.png";
// import Certificate17 from "../img/Certificate17.png";
// import Certificate18 from "../img/Certificate18.png";
// import Certificate19 from "../img/Certificate19.png";
// import Certificate20 from "../img/Certificate20.png";
// import Certificate21 from "../img/Certificate21.png";
// import Certificate22 from "../img/Certificate22.png";
// import Certificate23 from "../img/Certificate23.png";
// import Certificate24 from "../img/Certificate24.png";
// import Certificate25 from "../img/Certificate25.png";
// import Certificate26 from "../img/Certificate26.png";
// import Certificate27 from "../img/Certificate27.png";
// import Certificate28 from "../img/Certificate28.png";
// import Certificate29 from "../img/Certificate29.png";
// import Certificate30 from "../img/Certificate30.png";
// import Certificate31 from "../img/Certificate31.png";
// import Certificate32 from "../img/Certificate32.png";
// import Certificate33 from "../img/Certificate33.png";
// import Certificate34 from "../img/Certificate34.png"
// import Certificate35 from "../img/Certificate35.png"
// import Certificate36 from "../img/Certificate36.png"
// import Certificate37 from "../img/Certificate37.png"
// import Certificate38 from "../img/Certificate38.png"
// import Certificate39 from "../img/Certificate39.png"

const certificateImages = [
//   Certificate22,
//   Certificate5,
//   Certificate34,
  Certificate2,
  Certificate3,
//   Certificate24,
  Certificate1,
//   Certificate4,
//   Certificate38,
//   Certificate6,
//   Certificate8,
//   Certificate35,
//   Certificate9,
//   Certificate10,
//   Certificate11,
//   Certificate12,
//   Certificate13,
//   Certificate14,
//   Certificate15,
//   Certificate16,
//   Certificate17,
//   Certificate18,
//   Certificate19,
//   Certificate20,
//   Certificate21,
//   Certificate23,
//   Certificate25,
//   Certificate26,
//   Certificate27,
//   Certificate28,
//   Certificate29,
//   Certificate30,
//   Certificate31,
//   Certificate32,
//   Certificate33,
// Certificate36,
// Certificate37,
// Certificate39,
// Certificate7,
];

export default certificateImages;
